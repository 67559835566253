<template>
  <v-layout>
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list>

        <v-list-item link class="py-1" to="/companies/divisions/bookings">
          <v-list-item-action>
            <v-icon>fa-ticket-alt</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Bookings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-group prepend-icon="fa-map-marked-alt">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Groups</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link to="/companies/divisions/groups">
            <v-list-item-action>
              <v-icon>fa-list-alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>View All</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link to="/companies/divisions/groups/create">
            <v-list-item-action>
              <v-icon>fa-plus-circle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Add Group</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--
          ITEMS NOT AVAILABLE ON V1.0 BECAUSE I IMPLEMENTED IT DIRECTLY ASSOCIATED TO THE GROUP (IN THE COMPONENTS)
          THE DB STRUCTURE ALLOWS TO MANAGE ITEMS SEPARETALY AND ADD TO GROUPS LATER
        <v-divider></v-divider>

        <v-list-group prepend-icon="fa-layer-group">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Items</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link :to="`/companies/divisions/items`">
            <v-list-item-action>
              <v-icon>fa-list-alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>View All</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="`/companies/divisions/items/new`">
            <v-list-item-action>
              <v-icon>fa-plus-circle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Add Item</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        -->

        <v-divider></v-divider>

        <!-- <v-list-item link class="py-1">
          <v-list-item-action>
            <v-icon>fa-envelope-open-text</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Waitlists (in progress)</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider> -->

        <v-list-item link class="py-1" :to="`/companies/divisions/partners`">
          <v-list-item-action>
            <v-icon>fa-handshake</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Partners</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-group prepend-icon="fa-file-invoice">
          <template v-slot:activator>
            <v-list-item-title>Waivers</v-list-item-title>
          </template>
          <v-list-item link class="py-1" :to="`/companies/divisions/waivers/templates`">
            <v-list-item-action>
              <v-icon>fa-file-alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Waiver Templates</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link class="py-1" :to="`/companies/divisions/waivers`">
            <v-list-item-action>
              <v-icon>fa-file-signature</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Waivers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>

        <v-list-item link class="py-1" to="/companies/divisions/orders">
          <v-list-item-action>
            <v-icon>fa-chart-line</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link class="py-1" to="/companies/divisions/transactions">
          <v-list-item-action>
            <v-icon>fa-coins</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Transactions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dark color="primary" flat clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>Lola Groups | {{currentDivision.company.dba}} | {{currentDivision.name}}</v-toolbar-title>
      <v-spacer />

      <v-menu right bottom :close-on-content-click="false" :close-on-click="true">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>fa-ellipsis-v</v-icon>
          </v-btn>
        </template>

        
        <v-list>

          <!--
          <v-list-group prepend-icon="fa-users-cog" value="false">
            <template v-slot:activator>
              <v-list-item-title>Manage Users</v-list-item-title>
            </template>
            <v-list-item >
              <v-list-item-action>
                <v-icon>fa-user-plus</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Add new</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item >
              <v-list-item-action>
                <v-icon>fa-users</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>View all</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          -->
          <v-divider></v-divider>
          

           <v-list-item link @click="$router.push({name: 'divisions.choose'})">
            <v-list-item-action>
              <v-icon>fa-exchange-alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Change Division</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

           <v-divider></v-divider>

          <v-list-item link @click="$router.push({name: 'employees.list'})">
            <v-list-item-action>
              <v-icon>fa-users</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Manage Employees</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item link @click="logout()">
            <v-list-item-action>
              <v-icon>fa-sign-out-alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- END MENU BAR -->

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-footer app color="grey lighten-3">
      <v-row>
        <v-col class="text-left pb-0 pt-0">
          <span class="body-2">{{currentYear}} Lola Groups by Lolaguide Internet Inc.</span>          
        </v-col>

        <v-col class="text-right pb-0 pt-0">
          <span class="caption">User: {{loggedUser.user.name}}</span>

          <span v-if="environment === 'DEVELOPMENT'" class="subtitle-2 ml-4">Environment: {{environment}} - version {{version}}</span>
        </v-col>
      </v-row>
    </v-footer>
  </v-layout>
</template>

<script>
import environmentMixin from '@/mixins/environment-mixin.js'

export default {
  name: 'LayoutDashboardCompany',
  mixins: [environmentMixin],
  data: function () {
    return {
      drawer: true, //menu open by default            
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
    loggedUser: function(){
        return this.$store.getters.loggedUser
    },
    currentDivision: function(){
        return this.$store.getters.currentDivision
    }
  },
  methods: {
    logout() {      
      this.$store.dispatch('logout')
      this.$router.push('/login/employees')
    }
  },
}
</script>